<template>
  <v-container>
    <v-form
      ref="form"
      v-model="formValid"
    >
      <BaseMasterFieldBlock
        title="Отбор клиентов"
        description="Выберите клиентов, с которыми будет взаимодействовать данная активность."
      >
        <template v-slot:input>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="model.client_filter.type"
                class="mt-0"
                hide-details
                row
              >
                <v-radio
                v-for="(label, value) in CLIENT_FILTER_TYPE_ENUM"
                :key="value"
                :label="label"
                :value="value"                  
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="model.client_filter && model.client_filter.type === 'SQL'">
              <v-textarea
                v-model.trim="model.client_filter.sql"     
                :success-messages="clientFilterSuccessMessage"
                :error-messages="clientFilterErrorMessage"
                :loading="clientValidationAction"
                @blur="checkFilter"
                @input="resetClientValidation"
                :rules="[v => !!v || 'Поле sql обязательно для заполнения']"           
                auto-grow
                placeholder="Укажите фильтр"
                outlined
                maxlength="10000"                
              />
            </v-col>
            <v-col v-if="model.client_filter && model.client_filter.type === 'SEGMENTS'">
              <SegmentAutocomplete
              v-model="model.client_filter.segment_id_list"
              :items="segments"
              :rules="[v => v.length != 0 || 'Выбор сегментов обязателен']"
              hint="Отбор клиентов, которые принадлежат любому их выбранных сегментов."
              deletable-chips
              persistent-hint
              multiple
              clearable
              />
            </v-col>
            <v-col v-if="model.client_filter && model.client_filter.type === 'RULES'">
              <clients-query-builder :exportAndImportEnabled="true" v-model="model.client_filter.rules" />
            </v-col>
          </v-row>
        </template>
      </BaseMasterFieldBlock>

      <!--<v-row>
        <v-col class="_pa-0">
          <v-btn
            :disabled="!formValid"
            color="primary"
            class="master-next-btn"
            :loading="actionLoading"
            @click="onNextClick"
          >
            <p class="body-m-semibold mb-0">
              {{ actionText }}
            </p>
            <v-icon
              v-if="!!actionIcon"
              :rigth="!actionLeft"
            >
              {{ actionIcon }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>-->
    </v-form>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters} from 'vuex'
  import SegmentAutocomplete from '@/components/segment/SegmentAutocomplete.vue'  
  import ClientsQueryBuilder from "@/components/account/ClientsQueryBuilder.vue";

  export default {
    components: {
      SegmentAutocomplete,
      ClientsQueryBuilder
    },
    model: {
      prop: 'model',
      event: 'change',
    },
    props: {
      model: {
        type: Object,
        required: true,
      },
      actionText: {
        type: String,
        default: 'Далее',
      },
      actionLeft: Boolean,
      actionIcon: {
        type: String,
        default: '$iconify_heroicons-outline-arrow-right',
      },
      actionLoading: Boolean,
    },
    constants: {
      CLIENT_FILTER_TYPE_ENUM:  {
          "ALL": "Все",
          "SEGMENTS": "Сегмент",
          "RULES": "Конструктор",
          "SQL": "SQL",
        }
    },
    data () {
      return {
        formValid: false,
        clientFilterSuccessMessage: null,
        clientFilterErrorMessage: null,
        clientValidationAction: false,
        selectItm: null,        
      }
    },
    computed: {
      ...mapGetters({
        segments: 'crm/segment/segments',
        program: 'company/program/program'
      }),
      valid () {
        return this.formValid && !this.clientFilterErrorMessage
      },
      clientFilterRules () {
        return [
          this.checkFilter,
        ]
      },
    },
    watch:{
      valid: function (v){
        this.$emit('clientFilterValid', v)
      }
    },
    async created () {
      await this.getSegments({
        program_id: this.program.id,
      });
      if (this.model.client_filter.segment_id_list?.length > 0) {
        this.model.client_filter.segment_id_list.forEach((element, index) => {
          if (Number.isInteger(element)) {
            this.model.client_filter.segment_id_list[index] = this.segments.find(x => x.id === element)
          }
        })
      }
    },
    methods: {
      ...mapActions({
        CheckClientFilter: 'offers/offers/CheckClientFilter',
        getSegments: 'crm/segment/segments'
      }),
      resetClientValidation () {
        console.log('resetClientValidation')
        this.clientFilterSuccessMessage = null
        this.clientFilterErrorMessage = null
      },
      clientFilterChange (event) {
        console.log('event', event)
        this.clientFilterSuccessMessage = null
      },
      createEmptyFilter(){
        return {

        }
      },
      async checkFilter () {
        this.resetClientValidation()

        try {
          this.clientValidationAction = true
          

          if (this.model.client_filter && this.model.client_filter.type === 'SQL' && this.model.client_filter.sql) {
            if (this.model.client_filter.sql.length > 10000) throw new Error('Превышение максимальной длины')
              const postData = {
                program_id: this.model.program_id,
                client_filter: this.model.client_filter,
                with_trashed: this.model.with_trashed,
                goal_id: null,
              }
              //postData.client_filter.segment_id_list = []
              console.log("POST DATA",postData)
            const result = await this.CheckClientFilter(postData)
            if (result && !result.error) {
              this.clientFilterSuccessMessage = `В выборке ${result.count} клиентов`
            } else {
              this.clientFilterErrorMessage = result.error
            }
          }
        } catch (e) {
          console.error(e)
          this.clientFilterErrorMessage = e.message
        } finally {
          this.clientValidationAction = false
        }

        return !this.clientFilterErrorMessage
      },

      async validate () {
        return this.$refs.form.validate() && await this.checkFilter()
      },
      async onNextClick () {
        const valid = await this.validate()
        if (valid) {
          //this.$emit('continue', true)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>

@import '@/views/dashboard/form_component/_form-component.scss';
</style>
