<template>
  <v-select
    v-model="valueMutation"
    v-bind="$attrs"
    class=""
    :items="selectList"
    item-value="id"
    item-text="name"
    outlined
    hide-details
    :loading="loadingAction"
    :placeholder="$attrs.placeholder || 'Выберите сегмент номенклатуры'"
    style="max-width:380px"
    v-on="$listeners"
  >
    <template v-slot:selection="{item}">
      <segment-chip
        :name="item.name"
        :color="item.color"
        :chipAuto="item.type == 'auto'"
      />
    </template>
    <template v-slot:item="{item}">
      <segment-chip
        :name="item.name"
        :chipAuto="item.type == 'auto'"
        :color="item.color"
        max-width="380px"
      />
    </template>
  </v-select>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'NomenclatureSegmentSelect',
    components: {
      SegmentChip: () => import('@/components/segment/SegmentChip'),
    },
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [Number, Object, Array], default: undefined },
      programId: { type: [Number], default: undefined },
      selectFirst: { type: Boolean, default: false },
    },
    data () {
      return {
        loadingAction: false,

      }
    },
    computed: {
      ...mapGetters({
        globalProgramId: 'programId',
        pickList: 'company/nomen_segment/pickList',
      }),
      localProgramId () {
        return this.programId || this.globalProgramId
      },
      selectList () {
        return this.pickList
      },
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', v)
        },
      },
    },
    watch: {
      programId (v) {
        this.startAction()
      },
    },
    created () {
      this.startAction()
    },

    methods: {
      ...mapActions({
        getPickList: 'company/nomen_segment/getPickList',
      }),
      async startAction () {
        if (this.loadingAction) return
        try {
          this.loadingAction = true
          await this.getPickList({ programId: this.localProgramId })
          if (this.selectList.length > 0 && !this.valueMutation && this.selectFirst) this.valueMutation = this.selectList[0].id
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
