<template>
  <query-builder :exportAndImportEnabled="exportAndImportEnabled" :modelType="modelType"
                 v-model="valueMutation" :rules="builderRules" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QueryBuilder from "@/components/query-builder";
import SegmentAutocomplete from "@/components/segment/SegmentAutocomplete.vue";

export default {
  components: {
    QueryBuilder,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    exportAndImportEnabled: true,
    value: {
      type: Object,
      default: null,
    },
  }, 
  data() {
    return {
      modelType: 'account',
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      programId: "programId",
      allAccountFields: "settings/account-fields/fields",     
      pickSegments: 'crm/segment/pickSegments',
      bonusUnits: 'company/bonus_units/bonusUnits'
    }),
    valueMutation: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("input", v);
      },
    },
    accountFields() {
      return this.allAccountFields[this.programId] || [];
    },
    builderRules() {
      const baseRules = [
        {
          type: "numeric",
          id: "id",
          label: "ID",
        },
        {
          type: "text",
          id: "client_name",
          label: "Имя клиента",
        },
        {
          type: "phone",
          id: "phone",
          label: "Телефон",
        },
        {
          type: "date",
          id: "birthday",
          label: "Дата рождения (исходная)",
        },
        {
          type: "date",
          id: "birthday:pluscards_make_birthday",          
          label: "День рождения(в тек. году)",
        },   
        {
          type: "numeric",
          id: "birthday:pluscards_days_before_birthday",          
          label: "До дня рождения",
          props: { suffix: 'Дней', placeholder: 'N' }
        },       
        {
          type: "numeric",
          id: "birthday:pluscards_days_after_birthday",          
          label: "После дня рождения прошло",
          props: { suffix: 'Дней', placeholder: 'N' }
        },       
        {
          type: "custom-component",
          id: "segments",
          operators: [
            { id: "has", label: "содержит" },
            { id: "not_has", label: "не содержит" },
          ],
          component: SegmentAutocomplete,
          props: { returnObject: false, multiple: false, deletableChips: false, items: this.pickSegments },
          label: "Сегменты клиента",
          title: "Сегменты клиента",
        },
        {
          type: "select",
          id: "gender",
          label: "Пол",
          choices: [
            { value: "m", label: "Мужской" },
            { value: "f", label: "Женский" },
            { value: "n", label: "-" },
          ],
        },
        {
          type: "select",
          id: "user_gender",
          label: "Пол владельца",
          choices: [
            { value: "m", label: "Мужской" },
            { value: "f", label: "Женский" },
            { value: "n", label: "-" },
          ],
        },
        {
          type: "datetime",
          id: "created_at",
          label: "Дата регистрации",
        },
        {
          type: "switch",
          id: "consent_sms",
          label: "Согласие на смс",
        },
        {
          type: "switch",
          id: "consent_advertising",
          label: "Согласие на рекламу",
        },
        {
          type: "text",
          id: "comment",
          label: "Комментарий",
        },
        {
          type: "text",
          id: "email",
          label: "Почта",
        },
        {
          type: "numeric",
          id: "main_balance",
          label: "Баланс",
        },
        {
          type: "numeric",
          id: "sales_count",
          label: "Кол-во продаж",
        },
        {
          type: "numeric",
          id: "sales_cash_value_sum_rub",
          label: "Сумма всех оплат",
        },
        {
          type: "date",
          id: "first_sale_created_at:date",
          label: "Дата первой продажи",
        },
        {
          type: "date",
          id: "last_sale_created_at:date",
          label: "Дата последней продажи",
        },        
        {
          type: "switch",
          id: "is_referal",
          label: "Реферал",
        },
        {
          type: "switch",
          id: "is_referer",
          label: "Реферер",
        },
      ].concat(
          [
            { divider: true },
            { header: "Доп поля"}
          ].concat(
              this.accountFields.map((field) => {
                return {
                  label: field.name,
                  id: `fields[${field.id}].account_fields.value`,
                  type: this.getRuleType(field.type),
                };
              })
          )
      ).concat(
          [
              { divider: true },
              { header: "Балансы"}
          ].concat(
              this.bonusUnits.map((unit) => {
                return {
                  label: unit.name,
                  is_main: unit.is_main,
                  id: `bonus_unit_balances[${unit.id}].account_bonus_unit_balance_view.balance`,
                  type: "numeric",
                };
              })
          )
      );
      return baseRules;
    },
  },
  watch: {
    programId(v) {
      this.loadData();
    },
  },
  methods: {
    ...mapActions({
      GetAccountFields: "settings/account-fields/GetFields",
      getSegmentPickList: 'crm/segment/getPickList',
      getBonusUnits: 'company/bonus_units/loadBonusUnits'
    }),
    getRuleType(fieldType) {
      switch (fieldType) {
        case "float":
        case "integer":
          return "numeric";
        case "boolean":
          return "switch";
        case "date":
          return "date";
        case "datetime":
          return "datetime";
        case "select":
          return "select";
        default:
          return "text";
      }
    },   
    async loadData(){
      await this.GetAccountFields({ id: this.programId, withStub: false });
      await this.getSegmentPickList(this.programId);
      await this.getBonusUnits(this.programId)
    }
  },
  mounted() {
    this.loadData()
  },
};
</script>

<style lang="scss" scoped>
</style>
