<template>
  <div style="height: 100%;">
    <v-container class="container__main-step pa-0">
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <BaseMasterFieldBlock
          title="Название акции"
          description="Это название будет использоваться в отчетах об акции - клиенты не будут его видеть."
        >
          <template v-slot:input>
            <base-text-field
              v-model.trim="stepperData.name"
              class="field__name"
              placeholder="Название акции"
              minlength="1"
              maxlength="255"
              :rules="rules.name"
              validation-placement="top"
            />
          </template>
        </BaseMasterFieldBlock>
         <BaseMasterFieldBlock
          title="Приоритет"
          description="Если на позицию в чеке действует несколько акций, то будет применена акция с наибольшим приоритетом."
        >
          <template v-slot:input>
            <v-row no-gutters>
              <v-col class="pa-0">
                <base-text-field
                v-model="stepperData.priority"
                class="field__name"
                placeholder="Приоритет"
                :validate-on-blur="true"
                :key-filter-regexp="/(\d|Delete|Backspace|Enter)/"
                :rules="[
                  v => !!v || 'Обязательно для заполнения',
                  v => v <= $config.app.maxInt32Value || 'Не больше 2147483647',
                  v => v > 0 || 'Не меньше 0'
                ]"
              />
              </v-col>
            </v-row>
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock
          title="Срок действия"
          description="После окончания выбранного диапазона дат и времени, акция автоматически отключится."
        >
          <template v-slot:input>
            <v-row no-gutters>
              <v-col class="pa-0">
                <base-datetime-picker-field
                  v-model="stepperData.start_at"
                  class-name="field__start-at"
                  placeholder="Дата и время начала работы"
                  :rules="rules.dateStart"
                  :use-seconds="false"
                  clearable
                />
              </v-col>
              <v-col class="pa-0">
                <base-datetime-picker-field
                  v-model="stepperData.finish_at"
                  class-name="field__finish-at"
                  style=""
                  placeholder="Выберите дату и время окончания"
                  :rules="rules.dateFinish"
                  :use-seconds="false"
                  clearable
                />
              </v-col>
            </v-row>
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock title="Время работы акции" description="Выберите дни недели и время, в которое акция будет включаться">
          <template v-slot:input>
            <v-row no-gutters>
              <v-col>
                  <v-row>
                    <v-col>
                      <base-ext-switch v-model="isScheduled" label="По расписанию"></base-ext-switch>
                    </v-col>
                  </v-row>
                  <v-row v-for="item, idx of promotionScheduleEdit" :key="idx">
                    <v-col v-if="isScheduled" cols="2">
                      <base-ext-switch 
                        :label="promotionScheduleBase.daysOfWeak[idx]"
                        v-model="item.is_active">
                      </base-ext-switch>
                      </v-col>
                      <v-col v-if="isScheduled"> 
                        <base-text-field 
                          :disabled="!item.is_active"
                          maxlength = "5"
                          placeholder="С"
                          :rules="[
                            v => !!v || 'Введите время',
                            v => {
                              const patternTest = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) 
                              if (patternTest)
                                scheduleValid = true
                              else
                                scheduleValid = false
                              return patternTest || 'Время не соответсвует шаблону' 
                            }
                          ]"
                          v-model="item.min_time"> 
                        </base-text-field>
                      </v-col>
                      <v-col v-if="isScheduled"> 
                        <base-text-field 
                          :disabled="!item.is_active"
                          maxlength = "5"
                          placeholder="По"
                          :rules="[
                            v => !!v || 'Введите время',
                            v => {
                              const patternTest = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) 
                              if (patternTest)
                                scheduleValid = true
                              else
                                scheduleValid = false
                              return patternTest || 'Время не соответсвует шаблону' 
                            }
                          ]"
                          v-model="item.max_time"> 
                        </base-text-field>
                    </v-col>
                  </v-row>
                
              </v-col>
            </v-row>
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock
          title="Описание акции"
          description="Детально опишите условия проведения акции и правила участия."
        >
          <template v-slot:input>
            <v-row no-gutters>
              <v-col class="pa-0">
                <base-text-editor
                  v-model="stepperData.description"
                  placeholder="Правила и условия участия в акции"
                  :rules="rules.description"
                />
              </v-col>
            </v-row>
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock>
          <tab-filter 
            ref="tabFilter"
            v-model="stepperData"
            @clientFilterValid = "clientFilterValidation($event)"
            action-text="Сохранить"
            action-icon="">

          </tab-filter>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock
          title="Фильтр чека"
          description="Акция будет действовать на чек, если он удовлетворяет нижеследующим условиям. Если акция действует на любой чек - оставьте поле пустым."
        >
          <template v-slot:input>
            <v-row no-gutters>
              <v-col class="pa-0">
                <query-builder
                  v-model="stepperData.receipt_filter"
                  :rules="receiptBuilderRules"
                  :loading="loadingFilterRules"
                  modelType="receipt"
                />
                <!-- <nomenclature-segment-select
                  v-model="stepperData.receipt_nomenclature_segment_id"
                  placeholder="Выберите сегмент или оставьте пустым"
                  clearable
                /> -->
              </v-col>
            </v-row>
          </template>
        </BaseMasterFieldBlock>
      </v-form>
      <v-row>
        <v-col class="pa-0">
          <v-btn
            :disabled="!validData"
            color="primary"
            class="master-next-btn"
            @click="isEdit ? $emit('save') : $emit('continue')"
          >
            <v-icon
              v-if="isEdit"
              class="icon"
              size="21"
            >
              $iconify_ion-checkmark-circle-outline
            </v-icon>
            <p class="body-m-semibold mb-0">
              {{ isEdit ? 'Сохранить' : 'Далее' }}
            </p>
            <v-icon
              v-if="!isEdit"
              :rigth="true"
            >
              $iconify_heroicons-outline-arrow-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  // components
  import SegmentChip from '@/components/segment/SegmentChip'
  import NomenclatureSegmentSelect from '@/components/ModelSelect/NomenclatureSegmentSelect'
  import QueryBuilder from '@/components/query-builder'
  import TabFilter from '../stepComponents/tabFilter'
  // other
  import { mapGetters, mapActions } from 'vuex'
  import { trimLR } from '@/utils'

  export default {
    components: {
      SegmentChip, NomenclatureSegmentSelect, QueryBuilder, TabFilter
    },
    props: {
      stepperData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    constants: {
      DEFAULT_BROADCASTER: {
        name: '',
        description: '',
        start_at: null,
        finish_at: null,
        emit_mode: 'MANUAL',
        listen_event: null,
        parent_broadcaster_id: null,
        event_filter: null,
        period: null,
        with_trashed: false,
        async_process: true,
        handlers: [],
      },
    },
    inject: ['isEdit'],
    data () {
      return {
        isFormValid: false,
        scheduleValid : false,
        isScheduled : false,
        loadingSegmenPickList: false,
        loadingFilterRules: false,
        promotionScheduleBase :{
          is_scheduled : false, 
          daysOfWeak:[
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота",
            "Воскресенье",
          ],
        },
        promotionScheduleEdit:[
            {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"}
        ],
        clientFilterValid : false,
        rules: {
          name: [
            v => !!v || 'Введите имя акции',
            v => (!v || (v && trimLR(v).length <= 255)) || 'Имя не должно превышать 255 символов',
          ],
          description: [
            v => {
              if (!v || !v.length) {
                return true
              } else {
                return (this.strWithoutTags(trimLR(v)).length <= 255) || 'Описание не должно превышать 255 символов'
              }
            },
          ],
          dateStart: [
            v => {
              const start = this.stepperData.start_at
              const finish = this.stepperData.finish_at
              if (
                (!start || !finish) ||
                (!start.length || !finish.length)
              ) {
                return true
              } else {
                return (
                  this.$moment(finish).diff(start, 'seconds') > 0
                ) || 'Дата начала должна быть меньше чем дата окончания'
              }
            },
          ],
          dateFinish: [
            v => {
              const start = this.stepperData.start_at
              const finish = this.stepperData.finish_at

              if (
                (!start || !finish) ||
                (!start.length || !finish.length)
              ) {
                return true
              } else {
                return (
                  this.$moment(finish).diff(start, 'seconds') > 0
                ) || 'Дата окончания должна быть больше чем дата начала'
              }
            },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        pickSegments: 'crm/segment/pickSegments',
        nomenSegmentsPickList: 'company/nomen_segment/pickList',
        programId: 'programId',
      }),
      promotionSchedule(){
        if (this.stepperData.schedule_json)
        {
          let data = []
          for (let i = 1; i < 8; i++){
            data.push(this.stepperData.schedule_json[i+""])
          }
          console.log( "DATA", data)
          return data
        }
      },
      receiptBuilderRules(){
        return [
          {
            type: 'numeric',
            id: 'sum',
            label: 'Сумма чека',
          },
          {
            type: 'numeric',
            id: 'items_count',
            label: 'Кол-во позиций(строчек) чека',
          },
          {
            type: 'numeric',
            id: 'total_quantity',
            label: 'Суммарное кол-во товаров',
          },
          {
            type: 'custom-component',
            id: 'positionSegmentIdList',
            operators: ['contains'],
            component: NomenclatureSegmentSelect,
            props: { selectFirst: true },
            label: 'Позиция из сегмента',
            title: 'Имеется позиция из сегмента:',
          },
        ].concat(
          this.nomenSegmentsPickList.map(segment => {
            return {
              type: 'numeric',
              id: `segmentsItemsCount.${segment.id}` ,
              label: `Кол-во товаров из сегмента "${segment.name}""`,
            }
          })
        )
      },       
      validData () {
        const data = this.stepperData
        const cbkValues = [data.name, data.description, data.start_at, data.finish_at]
        let isValid = true
        Object.entries(this.rules).forEach(([key, arrRules], idx) => {
          arrRules.forEach(callback => {
            const check = callback(cbkValues[idx])
            if (
              typeof check !== 'boolean' ||
              check !== true
            ) {
              isValid = false
            }
          })
          
        })
        if (this.isScheduled && !this.scheduleValid)
          isValid = false
        if (!this.clientFilterValid)
          isValid = false
        return isValid
      },
    },
    watch: {
      // programId () {
      //   if (!this.stepperData.program_id) {
      //     this.init()
      //   }        
      // },
      isScheduled(){
        console.log("EDIT STEPPER DATA")
        this.stepperData.schedule_json.is_scheduled = this.isScheduled
        /*for (let i = 1; i < 8; i++){
          this.stepperData.schedule_json[i+""].is_active = this.isScheduled
        }*/
        console.log(this.stepperData.schedule_json)
      },
      promotionScheduleEdit:{
        handler(){  
          for (let i = 1; i < 8; i++){
            this.stepperData.schedule_json[i+""] = this.promotionScheduleEdit[i - 1]
          }
        },
        deep: true
      }
    },
    created () {
      this.init()
    },
    methods: {
      ...mapActions({
        getSegmentPickList: 'crm/segment/getPickList',
        getNomenSegmentsPickList: 'company/nomen_segment/getPickList',
      }),
      clientFilterValidation(event){
        console.log("CLIENT FILTER VALIDATION", event)
        this.clientFilterValid = event
      },
      async init(){
        const loadAccountSegments = this.loadPickSegments()
        const loadNomenSegments =  this.getNomenSegmentsPickListAction()
        if (!this.stepperData.client_filter)
          this.stepperData.client_filter = {
            type : "ALL",
            with_trashed: false
          }
        this.generatePromotionSchedule()
        return [await loadAccountSegments, await loadNomenSegments]
      },
      async getNomenSegmentsPickListAction(){
        this.loadingFilterRules = true
        try {         
          await this.getNomenSegmentsPickList({programId: this.stepperData.program_id || this.programId})
          
        } catch (e) {
          console.error(e)
        } finally {
          this.loadingFilterRules = false
        }
      },
      async loadPickSegments () {
        try {
          this.loadingSegmenPickList = true          
          await this.getSegmentPickList(this.stepperData.program_id || this.programId)
        } catch (e) {
          console.error(e)
        } finally {
          this.loadingSegmenPickList = false
        }
      },
      // setStepperData (keys, values) {
      //   console.log('setStepperData', keys, values)
      //   const stepperData = deepClone(this.stepperData)
      //   if (Array.isArray(keys)) {
      //     keys.forEach((key, idx) => {
      //       stepperData[key] = values[idx]
      //     })
      //   } else {
      //     stepperData[keys] = values
      //   }
      //   this.$emit('update:stepperData', stepperData)
      // },
      strWithoutTags (str) {
        console.log('strWithoutTags', str.replace(/<(\/?)[\w\\-]+>/ig, '').length)
        return str && str.replace(/<(\/?)[\w\\-]+>/ig, '')
      },
      generatePromotionSchedule(){
        console.log("STEPPER DATA BEFOR INIT", this.stepperData.schedule_json)
        if (!this.stepperData.schedule_json)
        {
          console.log("SCHEDULE INIT", this.stepperData)
          this.stepperData.schedule_json = {
            is_scheduled : false, 
            "1": {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            "2": {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            "3": {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            "4": {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            "5": {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            "6": {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"},
            "7": {is_active : false, is_all_day : false, min_time : "00:00", max_time : "23:59"}
          }
        }
        else{
          for (let i = 1; i < 8; i++){
            this.promotionScheduleEdit[i - 1] = this.stepperData.schedule_json[i+""];
          }
          this.isScheduled = this.stepperData.schedule_json.is_scheduled;
        }
        console.log("SCHEDULE AFTER INIT", this.stepperData)
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_typography";
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.container__main-step {
  .field__name {
    max-width: 328px;
    input {
      @include body-m-medium;
      &::placeholder {
        @include body-m-medium;
        color: $neutral-600;
      }
    }
  }
  & ::v-deep {
    .field__start-at {
      margin-right: 10px;
    }
    .field__finish-at {
      margin-left: 10px;
    }
  }
  .field__filter {
    max-width: 380px;
  }
}
</style>
